<script>
import {InsuredsListItem} from "@/views/osago/components";
import DocumentsBlock from "@/components/app/forms/DocumentsBlock.vue";
import {email, maxLength, minLength, required, requiredIf} from "vuelidate/lib/validators";
import validateForm from "@/views/tourism/mixins/validateForm";

export default {
    name: "PayerForm",
    components: {DocumentsBlock, InsuredsListItem},
    model: {
      prop: 'payer',
      event: 'change',
    },
    props: ['payer','searchInfo', 'submitCheck'],
    mixins:[validateForm],
    computed: {
        offerInfo() {
            return this.$store.getters.GET_TOURISM_INFO_OFFER;
        },
    },
    data(){
        return {
            LetterMaskError: false,
            isLoadInsuredsListBySurname: false,
            isLoadInsuredsList: false,
            insuredsListBySurname: {},
            errors: [],
            insuredsList: {},
        }
    },
    validations: {
        payer: {
            identificationCode:{required: requiredIf(function (nestedModel) {
                    return !this.payer.withoutIdentificationCode;
                }), customMinLength(value) {
                    if (this.payer.withoutIdentificationCode && (value === null || value === undefined || value === '')) {
                        return true;
                    }
                    return value !== undefined && value !== null && value.length === 10;
                }},
            name:{required, minLength: minLength(2), maxLength: maxLength(30),
                onlyLatinCharacters(value){
                    return this.onlyLatinCharactersAndNum(value);
                }
            },
            surname:{required: requiredIf(function (nestedModel) {
                    return true
                }), minLength: minLength(2), maxLength: maxLength(30),
                onlyLatinCharacters(value){
                    return this.onlyLatinCharacters(value);
                }},
            dateBirth: {
                required,
                // maxValue: moment().format('DD.MM.YYYY'),
                minLength: minLength(10),
                maxLength: maxLength(10),
                customMinLength(value) {
                    return value !== null && value.length === 10;
                },
                daysInMonth: function(value) {
                    if (value === null) {
                        return  true
                    }

                    return (parseInt(moment(value, 'DD.MM.YYYY').format('DD'))) <= moment(value, 'DD.MM.YYYY').daysInMonth();
                }
            },
            phone:{required,minLength: minLength(19)},
            mail:{required,email},
            city: {required, minLength: minLength(1)},
            street:{required,customMinLength(value) {
                    if (this.payer.street == null || this.payer.street == "") {
                        return true;
                    }
                    return value !== null && value.length >= 4;
                }},
            house:{required,minLength: minLength(1), onlyLatinAndNumAndSpecials(value){
                    return this.onlyLatinAndNumAndSpecials(value);
                }},
            apartment:{minLength: minLength(1), onlyLatinAndNumAndSpecials(value){
                    return this.onlyLatinAndNumAndSpecials(value);
                }},
        },
    },

}
</script>

<template>
<div>
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <h3>
                    <span>Дані страхувальника (Покупець)</span>
                </h3>
            </div>
            <div class="col-lg-12 row passport-row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-model="payer.name" @change="/*getClientDataForUpdating()*/" type="text" placeholder="Ім’я (Тільки латинськи літери)" v-on:keypress="LetterMask($event)"  :class="['form-control', {invalid: ((!$v.payer.name.required && submitCheck) ||  (!onlyLatinCharacters(payer.name)  && submitCheck))}]">
                        <small class="error" v-if="!$v.payer.name.required && submitCheck">Вкажіть ім’я </small>
                        <small class="error" v-if="LetterMaskError || !onlyLatinCharacters(payer.name)">Тільки латинські літери</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group surname-block">
                        <input v-model="payer.surname" @change="/*getClientDataForUpdating()*/"
                               maxlength="30"
                               type="text"
                               v-on:keypress="LetterMask($event)"
                               placeholder="Прізвище"
                               :class="['form-control surname-field',
                                                               {invalid: ((!$v.payer.surname.required && submitCheck) || (!$v.payer.surname.minLength) || (!$v.payer.surname.maxLength)) || (submitCheck && !onlyLatinCharacters(payer.surname))}]">
                        <div v-if="isLoadInsuredsListBySurname" class="load-insured-list spinner-border spinner-border-sm" role="status"></div>
                        <div v-if="insuredsListBySurname['payer'] && insuredsListBySurname['payer'].length > 0" class="autocomplete-surname">
                            <div @click="selectInsuredInfo(insured,'payer')" class="inn-items mb-1 mt-2" v-for="insured in insuredsListBySurname['payer']">
                                <insureds-list-item :insured="insured"></insureds-list-item>
                            </div>
                        </div>
                        <small class="error" v-if="!$v.payer.surname.required && submitCheck">Вкажіть прізвище (Тільки латинськи літери)</small>
                        <small class="error" v-if="LetterMaskError || !onlyLatinCharacters(payer.surname)">Тільки латинські літери</small>
                        <small class="error"
                               v-else-if="! $v.payer.surname.minLength || ! $v.payer.surname.maxLength">
                            Введіть коректно прізвище</small>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 row passport-row">
                <div class="col-lg-6">
                    <div class="form-group tooltip-group inn-block">
                        <input  type="text" placeholder="ІПН"
                                v-model="payer.identificationCode"
                                :disabled="payer.withoutIdentificationCode == true"
                                :class="['form-control inn-field', {invalid: hasError('payer.identificationCode') || (!$v.payer.identificationCode.required && submitCheck) || (!$v.payer.identificationCode.customMinLength && submitCheck)}]"
                                v-mask="'##########'" maxlength="10">
                        <div v-if="insuredsList['payer'] && insuredsList['payer'].length > 0" class="autocomplete-inn">
                            <div @click="selectInsuredInfo(insured,'payer')" class="inn-items mb-1 mt-2" v-for="insured in insuredsList['payer']">
                                <insureds-list-item :insured="insured"></insureds-list-item>
                            </div>
                        </div>
                        <i v-if="! isLoadInsuredsList" data-html="true" data-toggle="tooltip" data-select="true"
                           title="" class="icon question"
                           data-original-title="<img src='https://ukasko.ua/images/tooltips/inn.png'>"></i>
                        <div v-else class="load-insured-list spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <small class="error"
                               v-if="(!$v.payer.identificationCode.required && submitCheck)">Введіть ІПН</small>
                        <small class="error"
                               v-else-if="(!$v.payer.identificationCode.customMinLength && submitCheck)">
                            Введіть коректно ІПН
                        </small>
                        <small class="error" v-else-if="!$v.payer.withoutIdentificationCode && hasError('payer.identificationCode')">
                            {{ errors['payer.identificationCode'][0] }}
                        </small>
                        <small>
                            <div class="form-check">
                                <input v-model="payer.withoutIdentificationCode" @click="disableIdentificationCode()" type="checkbox"
                                       class="form-check-input" id="withoutIdentificationCode">
                                <label class="form-check-label" for="withoutIdentificationCode">Відсутній ІПН</label>
                            </div>
                        </small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-mask="'##.##.####'" placeholder="Дата народження" @change="getClientDataForUpdating()"
                               v-model="payer.dateBirth"
                               :class="['form-control', 'form-input_date', {invalid: ((!$v.payer.dateBirth.required && submitCheck) ||
                                                                (!$v.payer.dateBirth.customMinLength && submitCheck) ||
                                                                  (errorBirthDate()))}]">
                        <small class="error"
                               v-if="!$v.payer.dateBirth.required && submitCheck">Введіть дату народження</small>
                        <small class="error"
                               v-else-if="((!$v.payer.dateBirth.customMinLength || !$v.payer.dateBirth.daysInMonth) && submitCheck)">
                            Введіть коректну дату народження</small>
                        <!--                                                        <small class="invalid-feedback error" v-else-if="hasError('payer.dateBirth')" v-html="getError('payer.dateBirth')"></small>-->
                        <small class="invalid-feedback error" v-else-if="hasError('payer.errorBirthDate')" v-html="getError('payer.errorBirthDate')"></small>
                        <small class="error" v-else-if="errorTaxNumber()">
                            <small class="error" v-if="hasError('payer.errorBirthDate')">
                                {{ errors['payer.errorBirthDate'][0] }}
                            </small>
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 row passport-row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-model.trim="payer.mail" @change="/*getClientDataForUpdating()*/" type="text" placeholder="Ел. пошта" :class="['form-control', {invalid: (!$v.payer.mail.required && submitCheck)}]">
                        <small class="error" v-if="!$v.payer.mail.required && submitCheck">Вкажіть пошту</small>
                        <small class="error" v-else-if="!$v.payer.mail.email">Введіть коректно пошту</small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-model.trim="payer.phone"  @change="/*getClientDataForUpdating()*/" v-mask="'+38 (0##) ###-##-##'" type="text" placeholder="Телефон" :class="['form-control', {invalid: (!$v.payer.phone.required && submitCheck)}]">
                        <small class="error" v-if="!$v.payer.phone.required && submitCheck">Вкажіть телефон</small>
                        <small class="error" v-else-if="!$v.payer.phone.minLength">Введіть номер в форматі 380xxxxxxxx</small>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <DocumentsBlock
                    :productType="5"
                    :offerInfo="offerInfo"
                    :searchInfo="searchInfo"
                    :documentation="payer.documentation"
                    :submitCheck="submitCheck"
                    :dateBirth="payer.dateBirth"
                    @data-chenged="/*getClientDataForUpdating*/"
                ></DocumentsBlock>
            </div>

        </div>
    </div>
</div>
</template>

<style scoped>

</style>
