export default {
    methods: {
        LetterMask(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z ]+$/.test(char)) {
                this.LetterMaskError = false;
                return true;
            } // Match with regex
            else {
                this.LetterMaskError = true;
                e.preventDefault();
            }
        },
        validateTaxNumber(tax_number, date) {
            let tax_numberDate = moment('01.01.1900', 'DD.MM.YYYY').add(tax_number.substr(0, 5) - 1, 'day');
            let birthDate = moment(date, 'DD.MM.YYYY');

            return tax_numberDate.isSame(birthDate, 'year')
                && tax_numberDate.isSame(birthDate, 'month')
                && tax_numberDate.isSame(birthDate, 'day');
        },
        errorTaxNumber: function (index = false) {
            if(index){
                this.errors['tourist.identificationCode'] = [];
                this.errors['tourist.errorBirthDate'] = [];
            }

            if(index && (this.TouristsList[index].identificationCode == "" || this.TouristsList[index].identificationCode == null)){

            }else if(this.payer.identificationCode == "" || this.payer.identificationCode == null) {
                return false;
            }

            if(index && (this.TouristsList[index].dateBirth == "" || this.TouristsList[index].dateBirth == null)){

            }else if(this.payer.dateBirth == "" || this.payer.dateBirth == null) {
                return false;
            }

            if(index && (!this.TouristsList[index].withoutIdentificationCode
                && this.TouristsList[index].dateBirth.length == 10
                &&  this.TouristsList[index].identificationCode.length == 10
                && ! this.validateTaxNumber(this.TouristsList[index].identificationCode, this.TouristsList[index].dateBirth)
            )){
                this.errors['tourist.identificationCode'][index] = ["ІПН та Дата народження не співпадають"];
                this.errors['tourist.errorBirthDate'][index] = ["ІПН та Дата народження не співпадають"];
                return true;
            }else if(this.payer.dateBirth.length == 10 && !this.payer.withoutIdentificationCode && this.payer.identificationCode && this.payer.identificationCode.length == 10 && ! this.validateTaxNumber(this.payer.identificationCode, this.payer.dateBirth)) {
                this.errors['payer.identificationCode'] = ["ІПН та Дата народження не співпадають"];
                this.errors['payer.dateBirth'] = ["ІПН та Дата народження не співпадають"];
                return true;
            }else{
                delete this.errors['payer.identificationCode'];
                delete this.errors['payer.dateBirth'];
                if(index) {
                    delete this.errors['tourist.identificationCode'][index];
                    delete this.errors['tourist.errorBirthDate'][index];
                }
            }

            return false;
        },

        errorBirthDate: function () {
            if(this.payer.dateBirth == "" || this.payer.dateBirth == null || this.payer.dateBirth == "") {
                return false;
            }
            if((parseInt(moment(this.payer.dateBirth, 'DD.MM.YYYY').diff(moment(), 'years')) * -1) < 18) {
                this.errors['payer.errorBirthDate'] = ["Страхувальнику повинно бути більше 18 років"];

                return true;
            }else if((parseInt(moment(this.payer.dateBirth, 'DD.MM.YYYY').diff(moment(), 'years')-1) * -1) > 100) {
                this.errors['payer.errorBirthDate'] = ["Страхувальнику повинно бути не більше 100 років"];

                return true;
            }else{
                delete this.errors['payer.errorBirthDate'];
            }
            return false;
        },
        hasError(name, index = false) {
            if(!index) {
                return this.errors[name] !== undefined;
            }else{
                if(!!this.errors[name]){
                    return this.errors[name][index] !== undefined;
                }
                else return false;
            }
        },
        onlyLatinCharacters(str) {
            if(str == null || str == "") return true;
            return /^[a-zA-Z ]+$/.test(str);
        },
    },
}
